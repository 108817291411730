* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Code Pro", monospace;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.fancy-underline {
  background-image: linear-gradient(90deg, rgb(8, 132, 206), rgb(0, 0, 123));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.dynamicHello {
  height: 42px;
}
.workLists li {
  list-style-position: outside;
  margin-left: 2em;
}
.workTitle {
  float: right;
}
.intextImg {
  display: inline;
  height: 15px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #a4a4ce;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
}
html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
strong {
  color: black;
}

h1 span {
  color: white;
  background-image: linear-gradient(to right, rgb(0, 0, 123), rgb(8, 132, 206));
}
h2 span {
  color: white;
  background-image: linear-gradient(to right, rgb(0, 0, 123), rgb(8, 132, 206));
}

h4 {
  margin-top: 8px;
}

a:hover {
  background-color: #808080;
}
a:link,
a:visited {
  color: black;
  text-decoration: underline dotted rgb(61, 63, 63);
}
.Content a {
  color: #0831ff;
}

/* Navigation sidebar */
.SidebarContainer {
  color: #000;
  display: inline-block;
  width: 250px;
  vertical-align: top;
  padding-left: 0px;
}
.Sidebar {
  /* unselectable text */
  cursor: context-menu;
  padding: 0 0;
  width: 100%;
  margin-top: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.Sidebar li {
  display: block;
  background-color: rgb(189, 189, 189);
  color: #000;
}
.Sidebar ul {
  list-style-type: none;
  color: white;
  font-size: 25px;
}
li.selected-heading {
  color: white;
  background-image: linear-gradient(to right, rgb(0, 0, 123), rgb(8, 132, 206));
}

.Sidebar li:hover {
  background-color: rgb(0, 0, 123);
  color: white;
}
.sidebarhoverbox {
  width: 100%;
  background-color: rgb(189, 189, 189);
  border-left: 3px solid rgb(123, 123, 123);
  border-right: 3px solid rgb(123, 123, 123);
}
.Sidebar img {
  width: 15px;
}
.square {
  display: inline-block;
  margin: 5px 5px;
  border: 1px solid black;
  width: 20px;
  height: 20px;
}
.square:hover {
  border: 2px dotted grey;
}
#cMenu {
  /* border left, right is grey */
  color: green;
  text-align: center;
}
#cMenu:hover {
  background-color: transparent;
}

/* This is the class for anything that will display in the middle of the site */
.ContentContainer {
  width: 750px;
  padding: 10px 10px;
  padding-bottom: 75px;
  display: inline-block;
}
.Content {
  text-align: left;
  color: black;
  border: 6px double white;
  outline: 4px solid #c0c0c0;
  background-color: #c0c0c0;
  padding: 5px 10px;
  box-shadow: 0.5em 0.5em 3px 0 #525267;
}
.Content p {
  color: black;
}
.contentImg {
  text-align: center;
}
.contentImg img {
  width: 45px;
  margin: 5px 15px;
}
.contentImg a:hover {
  background-color: transparent;
}
.contentImg img:hover {
  background-color: #808080;
}
.centerImg {
  display: block;
  width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 1px 2px 2px 2px;
}
figure {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 80%;
  border: #808080 solid 2px;
}
figcaption {
  text-align: left;
  padding-left: 3px;
  padding-right: 2px;
  padding-top: 3px;
  border: 2px solid #c0c0c0;
  color: white;
  background-image: linear-gradient(to right, rgb(0, 0, 123), rgb(8, 132, 206));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
#figtext {
  display: inline-block;
  max-width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
}
button {
  margin-top: 2px;
  background: url("../public/close.svg") no-repeat center;
  background-color: #c0c0c0;
  background-position: center center;
  height: 15px;
  width: 15px;
  float: right;
}

/* Header */
header {
  background-color: #3465a4;
  margin: 0 0 0 0;
  border: 1px dotted green;
  text-align: center;
  color: white;
  padding: 3px;
  width: 100%;
}

/* Footer */
footer {
  position: fixed;
  height: 50px;
  background-color: #c0c0c0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer a {
  margin: 15px;
  padding: 10px 10px;
}
footer img {
  width: 15px;
}

@media screen and (max-width: 1000px) {
  .SidebarContainer {
    display: inline-block;
    width: 170px;
    margin: 0 0;
  }
  .Sidebar ul {
    padding: 0px 0;
  }
}
@media screen and (max-width: 920px) {
  .SidebarContainer,
  .ContentContainer {
    width: 85%;
    float: left;
    text-align: center;
    padding-left: 15%;
  }
  .Sidebar {
    padding: 0 0;
    margin: 0 0;
  }
  .Sidebar li {
    padding: 0 100;
    text-align: center;
  }
  .Sidebar ul {
    margin-top: 5px;
    padding: 0px 0;
  }
  body {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .SidebarContainer,
  .ContentContainer {
    width: 98%;
    float: left;
    padding-left: 2%;
  }
  .Sidebar {
    padding: 0 0;
    margin: 0 0;
  }
  .Sidebar li {
    padding: 0 100;
    text-align: center;
  }
  .Sidebar ul {
    padding: 0px 0;
    margin-top: 5px;
  }
  figure {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 90%;
  }
}
